import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";
import { useCallback, useState } from "react";
import useErrorModalContext from "src/pages/hooks/error-modal-context";

type ApiOperation<I, O> = (axios: AxiosInstance, input?: I) => Promise<O>;

type Options = {
  doNotErrorHandleModal?: boolean; // 에러 발생시 에러 모달 팝업 띄우는것 하지 않을 경우 true
};

// feature 1 : loading
// feature 2 : error
// feature 3 : token binding
// feature 4 : refresh token ? -> 자동으로 auth0 에서 만료된 토큰이면 재발급 받음
// feature 5 : common header
export default function useApiOperation<I, O>(apiOperation: ApiOperation<I, O>, options?: Options) {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const { openErrorModal } = useErrorModalContext();

  const executeAsync: (input?: I) => Promise<O> = useCallback(
    async (input?: I) => {
      try {
        setError(undefined);
        setLoading(true);

        // access token 가져오기
        await getAccessTokenSilently();

        // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
        const response = await getIdTokenClaims();
        const idToken = response?.__raw;

        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_PPOINT_BASEURL,
          headers: { Authorization: `Bearer ${idToken}` },
        });

        return await apiOperation(axiosInstance, input);
      } catch (_error: any) {
        console.error("executeAsync error", _error);

        setError(_error.response);

        if (!options?.doNotErrorHandleModal) {
          // 에러 메세지 모달 팝업
          let errorCode = "";
          let errorMessage = "";
          let errorData;
          if (_error?.response?.data?.meta?.errorCode) {
            errorCode = _error?.response?.data?.meta?.errorCode;
          }
          if (_error?.response?.data?.meta?.errorMessage) {
            errorMessage = _error?.response?.data?.meta?.errorMessage;
          }
          if (!errorMessage) {
            errorMessage = _error?.message || "에러가 발생했습니다.";
          }
          if (_error?.response?.data?.meta?.errorData) {
            errorData = _error?.response?.data?.meta?.errorData;
          }
          openErrorModal(errorMessage, errorCode, errorData);
        }

        // error 발생 response 전달
        const errorResponse: O = _error.response;
        return errorResponse;
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, getIdTokenClaims, apiOperation, options, openErrorModal],
  );

  return { loading, error, setError, executeAsync };
}
