import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import useApiOperation from "src/api/hooks/api-operation";
import {
  deleteWithdrawalAsync,
  getMemberDetail,
  getMembers,
  postWithdrawalAsync,
} from "src/api/member/member-api";
import {
  MemberAccountState,
  MemberAccountStatusType,
  MemberLoginModel,
  MemberModel,
  MemberSearchStatusOptions,
  MemberSearchTypeOptions,
  MemberStateStatus,
  OrganizationsList,
  QueryParams,
  SearchPageInitData,
} from "src/api/member/member-types";
import { PageMeta } from "src/api/public-types";
import { getReservationListAsync } from "src/api/reservation/reservation-api";
import { getContractUseRequests } from "src/api/user/user-api";
import { getVisitorListAsync } from "src/api/visitor/visitor-api";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTooltip } from "src/components";
import { BaseTableWithSelect } from "src/components/BaseTableWithSelect";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import LoadingBar from "src/components/LoadingBar";
import MemberAdminMemoPreview from "src/components/adminmemo/MemberAdminMemoPreview";
import PagePath from "src/pagePath.json";
import { Modal } from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { YmdFormat, formatPhoneNumber } from "src/utils";
import MemberAccountChangeHistory from "./components/MemberAccountChangeHistory";
import TextAttribute from "./components/TextAttribure";
import TextWithBtn from "./components/TextWithBtn";
import MemberEmailUpdate from "./modal/MemberEmailUpdate";
import PopupListMemberColumns from "./modal/columns/MemberColumns";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";

//페이지 제공 기본 데이터
const initData: SearchPageInitData = {
  memberSearchStatusOptions: MemberSearchStatusOptions,
  memberSearchTypeOptions: MemberSearchTypeOptions,
};
const disableSortHeaders = ["id", "memberNo", "PhoneNumber", "email", "accountState"];

const MemberBasicInfo = ({}) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // const location = useLocation();
  const navigate = useNavigate();

  const param = useParams();

  const memberId = param.id;

  const [memberDetail, setMemberDetail] = useState<MemberModel>();

  const [loginInfo, setLoginInfo] = useState<MemberLoginModel>();

  const [selectedCategory, setSelectedCategory] = useState("");

  //계정연결 시 조회되는 모든 멤버정보
  const [memberAll, setMemberAll] = useState<MemberModel[]>([]);
  //페이징
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  //계정상태 변경내역 조회 API
  const { loading: loading2, executeAsync: executeAsyncAll } = useApiOperation(getMembers);
  //조회용 params (안쓸지도?)
  const [params] = useState<QueryParams>();
  //엑세스 권한 상세보기 링크
  // const path = PathJson.accessControl.detail.replace(":id", memberDetail?.id! + "");
  //계정연결 팝업 flag
  const [modalViewFlag, setModalViewFlag] = useState<boolean>(false);
  //선택한 얀걀 계정
  const [selected, setSelected] = useState<any>({});
  //테이블 radio 사용 트리거
  const [useSingleSelect] = useState(true);
  //가입일시
  const [joinDate, setJoinDate] = useState("");
  //마케팅 동의 여부
  const [marketing, setMarketing] = useState("");
  //계정상태 변경내역 조회를 위한 회원번호
  const [memberNo, setMemberNo] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [orgs, setOrgs] = useState<Array<OrganizationsList>>([]);
  const [emailString, setEmailString] = useState("");

  // 이메일 변경 팝업 input value
  const [newEmail, setNewEmail] = useState(memberDetail?.email || "");

  // 이메일 변경 input 유효성
  const [emailError, setEmailError] = useState(false);

  // 이메일 수정 모달
  const [editEmailModal, setEditEmailModal] = useState<Modal>({ isOpen: false });

  // 이메일 수정 모달
  const [saveEmailModal, setSaveEmailModal] = useState<Modal>({ isOpen: false });

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 회원탈퇴 모달
  const [withdrawModal, setWithdrawModal] = useState<Modal>({ isOpen: false });

  //회원 상세 조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  // 회원탈퇴 조건검사 :: 이용신청 목록조회
  const { executeAsync: getContractUseRequest } = useApiOperation(getContractUseRequests);

  // 회원탈퇴 조건검사 :: 공용공간 목록조회
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);

  // 회원탈퇴 조건검사 :: 방문자 목록조회
  const { executeAsync: getVisitorList } = useApiOperation(getVisitorListAsync);

  // 회원탈퇴
  const { executeAsync: postWithdrawal } = useApiOperation(postWithdrawalAsync);

  // 회원탈퇴 취소요청
  const { executeAsync: deleteWithdrawal } = useApiOperation(deleteWithdrawalAsync);

  //모달 팝업
  const onChangeValue = useCallback((modalViewFlag) => {
    setModalViewFlag(true);
  }, []);

  const onChangeMemberAccount = useCallback(() => {
    // console.log("계정선택 팝업 저장 클릭", selected);
  }, []);

  useTitleOperation(memberDetail?.memberNo);

  const textToMemberAccountState = useCallback((value: MemberAccountState) => {
    let accountStatus = "";
    let fullTime = "";
    if (value && value.statusDate) {
      fullTime = moment(value.statusDate).format("YYYY-MM-DD HH:mm:ss");
      switch (value.status) {
        case MemberAccountStatusType.AVAILABLE:
          //정상
          accountStatus = "정상";
          break;
        case MemberAccountStatusType.LOCKED:
          // 계정 잠김
          accountStatus = "일시 정지";
          break;
        case MemberAccountStatusType.INACTIVE:
          // 일시 정지
          accountStatus = "휴면 계정";
          break;
        case MemberAccountStatusType.DELETE:
          // 휴면 계정
          accountStatus = "회원 탈퇴";
          break;
        case MemberAccountStatusType.WITHDRAWAL_REQUESTED:
          // 휴면 계정
          accountStatus = "탈퇴 요청";
          break;
      }
      setAccountStatus(`${accountStatus} (${fullTime})`);
    }
  }, []);

  const onClosePopup = () => {
    setModalViewFlag(false);
  };
  const chagnePage = ({ page }: any) => {};

  const getMemberDetailApi = useCallback(
    async (id: string) => {
      if (id) {
        setLoadingBar(true);
        const response = await getMemberDetailAsync({ id });
        if (response.status >= 200 && response.status <= 299) {
          const memberData = response?.data?.data?.member;

          setMemberDetail(memberData || null);
          setLoginInfo(response?.data?.data?.login);

          if (memberData) {
            setMemberNo(memberData?.memberNo);

            setNewEmail(memberData.email);

            const findedJoinState = memberData?.states?.find(
              (status: MemberStateStatus) => status.status === "JOINED",
            );
            if (findedJoinState) {
              setJoinDate(moment(findedJoinState.statusDate).format("YYYY-MM-DD - HH:mm"));
            }
            const findedMCState = memberData?.states.find(
              (status: MemberStateStatus) => status.status === "MARKETING_CONSENT",
            );
            if (findedMCState) {
              setMarketing(
                "동의 (" + moment(findedMCState.statusDate).format("YYYY-MM-DD - HH:mm:ss") + ")",
              );
            }
            const findedMCDState = memberData?.states.find(
              (status: MemberStateStatus) => status.status === "MARKETING_CONSENT_DISAGREE",
            );
            if (findedMCDState) {
              setMarketing(
                "비동의 (" +
                  moment(findedMCDState.statusDate).format("YYYY-MM-DD - HH:mm:ss") +
                  ")",
              );
            }

            textToMemberAccountState(memberData?.accountState);
            setOrgs(memberData?.organizations);
            if (memberData?.email !== "") {
              const emailStr = memberData?.email!.slice(0, 1);
              setEmailString(emailStr!.toUpperCase());
            }
          }
          setLoadingBar(false);
        }
      }
    },
    [getMemberDetailAsync, setLoadingBar, textToMemberAccountState],
  );

  const isAvailableWithdrawal = () => {
    if (
      memberDetail?.accountState.status === MemberAccountStatusType.WITHDRAWAL_REQUESTED ||
      memberDetail?.accountState.status === MemberAccountStatusType.DELETE
    ) {
      return false;
    }
    return true;
  };

  //회원탈퇴 가능여부 체크
  const checkValidation = async () => {
    // 방문자초대 탈퇴조건 체크
    const { data: visitorData, status: visitorStatus } = await getVisitorList({
      inviteeMemberNo: memberDetail?.memberNo,
      page: 0,
      size: 20,
      visitStatus: "VISIT_USE",
      sort: {
        orders: [{ property: "visitStartTime", direction: "DESC" }],
      },
    });
    if (visitorStatus >= 200 && visitorStatus < 300) {
      const visitors = visitorData.data.content;
      if (visitors && visitors.length > 0) {
        setWithdrawModal({
          isOpen: true,
          type: "visitor",
          payload: `${PagePath.visitor.list}?page=0&size=20&sort.orders[0].property=visitStartTime&sort.orders[0].direction=DESC&visitStatus=VISIT_USE&inviteeMemberNo=${memberDetail?.memberNo}`,
        });
        return;
      }
    }

    // 공용공간예약 탈퇴조건 체크
    const { data: reservationData, status: reservationStatus } = await getReservationList({
      containsOrganizerMemberNo: memberDetail?.memberNo,
      sort: {
        orders: [{ property: "start", direction: "DESC" }],
      },
      statusCode: "ACCEPTED,ACKNOWLEDGED,INCOMPLETE",
      start: moment().format(YmdFormat.WITH_TIME_ZONE),
    });
    if (reservationStatus >= 200 && reservationStatus < 300) {
      const reservations = reservationData.data.content;
      if (reservations && reservations.length > 0) {
        const reservationIds = reservations.map((reservation: any) => reservation.id).join(",");
        const minStartDate = reservations.sort((a: any, b: any) => {
          return moment(a.start).isBefore(moment(b.start)) ? -1 : 1;
        })[0]?.start;
        const maxEndDate = reservations.sort((a: any, b: any) => {
          return moment(a.start).isBefore(moment(b.start)) ? -1 : 1;
        })[reservations.length - 1]?.end;
        setWithdrawModal({
          isOpen: true,
          type: "reservation",
          payload: `${PagePath.reservation.list}?page=0&size=20&sort.orders[0].property=start&sort.orders[0].direction=DESC&id=${reservationIds}&start=${minStartDate}&end=${maxEndDate}`,
        });
        return;
      }
    }

    // 신청계약 유효한 데이터가 남아있는지 확인
    const checkStatus = [
      ContractStep.APPLY_RECEIVED,
      ContractStep.APPLY_CONFIRM,
      ContractStep.CONTRACT_PLAN,
      ContractStep.CONTRACT_ACCEPT,
      ContractStep.USE_APPROVAL,
      ContractStep.USE_PROGRESS,
      ContractStep.TERMINATE_RECEIVED,
    ];
    const contractSteps = checkStatus.join(",");
    // 신청계약 탈퇴조건 체크
    const { data: contractData, status: contractStatus } = await getContractUseRequest({
      memberNo: memberDetail?.memberNo,
      isContractor: true,
      contractSteps,
    });
    if (contractStatus >= 200 && contractStatus < 300) {
      const useRequestList = contractData.data.content;
      if (useRequestList && useRequestList.length > 0) {
        const contractIds = useRequestList.map((contract: any) => contract.contractId).join(",");
        setWithdrawModal({
          isOpen: true,
          type: "contract",
          payload: `${PagePath.contract.list}?page=0&size=20&sort.orders[0].property=contractManageId&sort.orders[0].direction=DESC&contractIds=${contractIds}`,
        });
        return;
      }
    }
    // 다 통과 시
    setWithdrawModal({
      isOpen: true,
      type: "availableWithdraw",
    });
  };

  const renderWithdrawalModal = (type: string | undefined) => {
    switch (type) {
      case "contract": // 신청계약 탈퇴불가조건
        return (
          <>
            <h4 className="font16 mb20">신청/계약에 잔존 데이터가 있어서 탈퇴요청이 불가합니다</h4>
            <p>해당 신청/계약 목록을 확인하시겠습니까?</p>
          </>
        );

      case "reservation": // 공용공간 탈퇴불가조건
        return (
          <>
            <h4 className="font16 mb20">
              공용공간 예약에 잔존 데이터가 있어서 탈퇴요청이 불가합니다
            </h4>
            <p>해당 공용공간 예약 목록을 확인하시겠습니까?</p>
          </>
        );

      case "visitor": // 방문자초대 탈퇴불가조건
        return (
          <>
            <h4 className="font16 mb20">
              방문자 초대에 잔존 데이터가 있어서 탈퇴요청이 불가합니다
            </h4>
            <p>해당 방문자 초대 목록을 확인하시겠습니까?</p>
          </>
        );

      case "availableWithdraw": // 탈퇴가능
        return (
          <>
            {/* <h4 className="font16 mb20">
              방문자 초대에 잔존 데이터가 있어서 탈퇴요청이 불가합니다
            </h4> */}
            <p>
              회원 탈퇴시, 해당 회원은 즉시 모든 신청/계약에서 이용자 삭제가 되고, <br />
              공용공간 예약도 즉시 불가해 지며, 회원 상태는 탈퇴 요청 상태가 됩니다.
              <br />
              <br />
              이후, 1~2일 내에 신청/계약, 공용공간 예약, 이용자 초대, 방문자 초대, 출입, CS 등에서{" "}
              <br />
              해당 회원의 폰번호, 이메일, 닉네임이 모두 삭제되며, 회원 상태는 탈퇴 완료 상태가
              됩니다.
              <br />
              <br />
              그리고, 탈퇴가 완료되기 전까지는 해당 폰번호로는 재가입이 불가합니다.
              <br />
              <br />
              회원 탈퇴를 요청하시겠습니까?
            </p>
          </>
        );
      default:
        return <>문제가 발생했습니다</>;
    }
  };

  const fetchWithDraw = async () => {
    if (memberDetail?.memberNo) {
      const { data, status } = await postWithdrawal({ memberNo: memberDetail?.memberNo });
      if (status >= 200 && status < 300) {
        getMemberDetailApi(String(memberId));
        console.log("탈퇴성공", data);
      }
      setWithdrawModal({ isOpen: false });
    }
  };
  const handleDeleteWithdrawal = async () => {
    if (memberDetail?.memberNo) {
      const { data, status } = await deleteWithdrawal({ memberNo: memberDetail?.memberNo });
      if (status >= 200 && status < 300) {
        getMemberDetailApi(String(memberId));
      }
    }
  };

  useEffect(() => {
    getMemberDetailApi(String(memberId));
  }, [getMemberDetailApi, memberId]);

  useEffect(() => {
    if (modalViewFlag) {
      executeAsyncAll(params).then((response) => {
        setMemberAll(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      });
    }
  }, [executeAsyncAll, modalViewFlag, params]);

  const emailVerifiedCheck = useCallback((states: Array<MemberStateStatus>) => {
    if (states) {
      const isEmailVerified = states.some((item) => item.status === "EMAIL_VERIFIED");

      if (isEmailVerified === true) {
        return <span className="validation-text">인증</span>;
      } else {
        return <span className="validation-text">미인증</span>;
      }
    }
  }, []);
  useTitleOperation(memberDetail?.memberNo);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <LoadingBar visible={loading2} />
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="기본정보"
          id={memberDetail?.id}
          noTitle="회원번호"
          no={memberDetail?.memberNo}
        />

        {/* 유저 기본정보 */}
        <section className="user-basic-info">
          <div className="user-img-container minmax80">
            <span>{emailString}</span>
          </div>
          <div className="user-info-container">
            <div className="flex-center">
              {/* uid */}
              <TextAttribute title={"id"} value={memberDetail?.id} />

              {/* 닉네임 */}
              <TextAttribute title={"닉네임"} value={memberDetail?.nickname} />
            </div>
            <div className="flex-center">
              {/* 이메일 주소 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>이메일 주소 </p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax300 flex-center-start flex-row">
                    <p>{memberDetail?.email}</p>
                    <div className="ml10">
                      {memberDetail && emailVerifiedCheck(memberDetail?.states)}
                    </div>
                    <div>
                      <BaseButton
                        title="수정"
                        className="color-white ml20"
                        onClick={() => setEditEmailModal({ isOpen: true })}
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* member ID */}
              <TextAttribute title={"회원번호"} value={memberDetail?.memberNo} />
            </div>
            <div className="flex-center">
              {/* 가입일시 */}
              <TextAttribute title={"가입일시"} value={joinDate} />
              {/* 휴대폰 번호 */}
              <TextWithBtn
                title={"휴대폰 번호"}
                value={
                  memberDetail?.phoneNumber ? formatPhoneNumber(memberDetail?.phoneNumber) : "-"
                }
                btnTitle={"계정 연결"}
                onClick={onChangeValue}
              />
            </div>
            <div className="flex-center">
              {/* 마케팅 동의 여부 */}
              <TextAttribute title={"마케팅 동의 여부"} value={marketing} />
              {/* 계정상태 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>계정 상태 </p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax340 flex-center-start flex-row">
                    <p>{accountStatus}</p>
                    <div>
                      {memberDetail?.accountState.status ===
                        MemberAccountStatusType.WITHDRAWAL_REQUESTED && (
                        <BaseButton
                          title="탈퇴 취소"
                          onClick={() => handleDeleteWithdrawal()}
                          className="color-white ml20"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-start">
              {/* 단말기 정보 */}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>단말기 정보 </p>
                </div>
                <div className="contents-container__grid-contents">
                  {loginInfo && (
                    <div className="pt7">
                      <span className="mr5">{loginInfo?.client?.device?.family}</span>
                      <span className="mr5">
                        ({loginInfo.client.userAgent.family} {loginInfo.client.userAgent.major})
                      </span>
                      <span>
                        {loginInfo.client.os.family} {loginInfo.client.os.major}
                      </span>
                    </div>
                  )}
                </div>
              </section>

              {/* 최종 로그인 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 로그인 </p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax240">
                    <BaseTooltip contents={loginInfo?.authTime} type="date" />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <MemberAccountChangeHistory memberNo={memberNo} />

        <AdminMemoV3
          serviceId={memberDetail?.id!}
          serviceType={ServiceTypes.SERVICE_MEMBER}
          partnerId={"0"}
        ></AdminMemoV3>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
          {isAvailableWithdrawal() && (
            <BaseButton
              title="회원탈퇴"
              onClick={() => checkValidation()}
              className="color-white no-outline ml10 size-large text-red"
            />
          )}
        </div>
        <div className="right-area"></div>
      </div>
      <div>
        <BaseModal
          className="dialog-modal"
          isOpen={modalViewFlag}
          btnRightTitle="선택"
          onClick={onChangeMemberAccount}
          btnLeftTitle="취소"
          onClose={onClosePopup}
        >
          <>
            <div className="modal-title">
              <div className="left-area">
                <span>계정 연결</span>
              </div>
              <div className="right-area">
                <div className="minmax120 mr8">
                  <BaseSelect
                    value={selectedCategory}
                    stateOptions={initData.memberSearchTypeOptions}
                    setStateValue={setSelectedCategory}
                  />
                </div>
                <div className="minmax260">
                  <BaseInput
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    onSearchClick={() => {}}
                  />
                </div>
              </div>
            </div>
            {/* table */}
            <BaseTableWithSelect
              data={memberAll}
              columns={PopupListMemberColumns}
              pageIndex={Number(params?.page || 0)}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                chagnePage({ page });
              }}
              disabledSortHeaders={disableSortHeaders}
              setOrders={() => {}}
              useSingleSelect={useSingleSelect}
              selected={setSelected}
              wasSeleted={selected}
            />
          </>
        </BaseModal>
      </div>
      <div>
        {editEmailModal.isOpen && (
          <BaseModal
            isOpen={editEmailModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="저장"
            btnRightDisable={newEmail === memberDetail?.email ? true : false}
            onClose={() => setEditEmailModal({ isOpen: false })}
            onClick={() => {
              // if (newEmail === memberDetail?.email) {
              //   setAlertModal({
              //     isOpen: true,
              //   });
              //   return;
              // }
              !emailError &&
                setSaveEmailModal({ isOpen: true, message: "이메일을 변경하시겠습니까?" });
            }}
          >
            <MemberEmailUpdate
              memberDetail={memberDetail}
              accountStatus={accountStatus}
              saveEmailModal={saveEmailModal}
              setSaveEmailModal={setSaveEmailModal}
              setEditEmailModal={setEditEmailModal}
              memberId={memberId}
              getMemberDetailApi={getMemberDetailApi}
              setEmailError={setEmailError}
              newEmail={newEmail}
              setNewEmail={setNewEmail}
            />
          </BaseModal>
        )}
      </div>
      <div>
        {alertModal.isOpen && (
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <div>
              <h4 className="font16 mb20">기존 이메일과 동일합니다.</h4>
              <p>이메일 변경이 불가능합니다.</p>
              <p className="my5">다시 입력하세요.</p>
            </div>
          </BaseModal>
        )}
        {withdrawModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setWithdrawModal({ isOpen: false })}
            onClick={() => {
              if (withdrawModal.type === "availableWithdraw") {
                fetchWithDraw();
              } else {
                window.open(withdrawModal.payload, "_blank", "noopener,noreferrer");
                setWithdrawModal({ isOpen: false });
              }
            }}
          >
            <div>{renderWithdrawalModal(withdrawModal.type)}</div>
          </BaseModal>
        )}
      </div>
    </div>
  );
};
export default MemberBasicInfo;
